
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import hrmService from "@/services/hrm-service";
import utils from "@/utils/utils";
import PopUp from "@/components/PopUp.vue";
import Loader from "@/components/loader.vue"; // @ is an alias to /src


@Component({
    components: {
        PopUp,
        Loader,
        Header
    },
})
export default class Roaster extends Vue {
    loading = true;
    public currentPage = 1;
    selectedOffDays: any = [] // Initially, no days are selected
    singleDaysOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
    // modal end
    fields = [
        { key: "code", sortable: true },
        { key: "title" },
        { key: "shift_type" },
        { key: "full_ot", label: "Full Time" },
        { key: "full_ut", label: "Full Undertime" },
        { key: "half_ot", label: "Half Time" },
        { key: "half_ut", label: "Half Undertime" },
        { key: "break_time", label: "Break Time" },
        {
            key: 'action', label: 'Action'
        },
    ]
    sidebarFields = [
        { key: "mon_type" },
        { key: "mon_start" },
        { key: "mon_end", },
        { key: "tue_type" },
        { key: "tue_start" },
        { key: "tue_end", },
        { key: "wed_type" },
        { key: "wed_start" },
        { key: "wed_end", },
        { key: "thu_type" },
        { key: "thu_start" },
        { key: "thu_end", },
        { key: "wed_type" },
        { key: "wed_start" },
        { key: "wed_end", },
        { key: "thu_type" },
        { key: "thu_start" },
        { key: "thu_end", },
        { key: "fri_type" },
        { key: "fri_start" },
        { key: "fri_end", },
        { key: "sat_type" },
        { key: "sat_start" },
        { key: "sat_end", },
        { key: "sun_type" },
        { key: "sun_start" },
        { key: "sun_end", },
    ]
    day: any = {};
    monday_value: any = {};
    tuesday_value: any = {};
    wednesday_value: any = {};
    thursday_value: any = {};
    friday_value: any = {};
    saturday_value: any = {};
    sunday_value: any = {};
    sidebarVisible = false;
    selectedItem: any = [];
    options = [
        { value: null, text: "Please select an option" },
        { value: "Full", text: "Full" },
        { value: "Half", text: "Half" },
        { value: "Off", text: "Off" },
    ];
    wordays_setting: any = {
    };
    typeOpt = [
        { value: null, text: "Please select an option" },
        { value: "indvidual", text: "Individual Working Days" },
        { value: "single", text: "Same Working Days" },
    ]
    single: any = {
        type: "",
        start: "",
        end: "",
    }
    error: any = ""
    create() {
        this.error = ""
        const error_list = utils.getEmptyKeys(this.wordays_setting, ['code', 'title', 'shift_type', 'full_ot', 'full_ut', 'half_ot', 'half_ut', 'break_time'])
        if (error_list.length > 0) {
            this.error = utils.generateErrorMessage(error_list)
        }
        else {
            if (this.wordays_setting.shift_type === 'single') {
                this.validateTimeForSingle(this.selectedOffDays, this.single['start'], this.single['end'])
                const error_list = utils.getEmptyKeys(this.single, ['type', 'start', 'end'])
                if (error_list.length > 0) {
                    this.error = "Please Insert Type With Start Date And End Date"
                }
                else {
                    if (!this.forUpdate) {
                        hrmService.createWorkingDays(this.wordays_setting).then((response) => {
                            this.showModal("Succesfully created", [], "success");
                            this.retreive()
                            this.forUpdate = false;
                            this.clear();
                            this.selectedOffDays = []
                        })
                            .catch((err) => {
                                if (err.response && err.response.status === 400) {
                                    // Handle the 400 Bad Request error
                                    this.showModal("", err.response.data, "errorArray");
                                } else {
                                    // Handle other types of errors (e.g., network issues, server errors)
                                    console.error('Unexpected error:', err);
                                    this.showModal('An unexpected error occurred', [], "error");
                                }
                            })
                    } else {
                        hrmService.updateWorkingDays(this.wordays_setting, this.wordays_setting.id).then((response) => {
                            // alert('updated working days')
                            this.retreive()
                            this.showModal("Succesfully updated", [], "success");
                            this.forUpdate = false
                            this.clear();
                            this.selectedOffDays = []
                        }).catch((err) => {
                            if (err.response && err.response.status === 400) {
                                // Handle the 400 Bad Request error
                                this.showModal("", err.response.data, "errorArray");
                            } else {
                                // Handle other types of errors (e.g., network issues, server errors)
                                console.error('Unexpected error:', err);
                                this.showModal('An unexpected error occurred', [], "error");
                            }
                        })
                    }
                }
            }
            else {
                if (this.validateTime()) {
                    const error_list = utils.getEmptyKeys(this.wordays_setting, [
                        'mon_type', 'mon_start', 'mon_end',
                        'tue_type', 'tue_start', 'tue_end',
                        'wed_type', 'wed_start', 'wed_end',
                        'thu_type', 'thu_start', 'thu_end',
                        'fri_type', 'fri_start', 'fri_end',
                        'sat_type', 'sat_start', 'sat_end',
                        'sun_type', 'sun_start', 'sun_end'
                    ])
                    if (error_list.length > 0) {
                        this.error = utils.generateErrorMessage(error_list)
                    }
                    else {
                        if (!this.forUpdate) {
                            hrmService.createWorkingDays(this.wordays_setting).then((response) => {
                                // alert('created working days')
                                // this.items = response.data
                                this.retreive()
                                this.showModal("Succesfully created", [], "success");
                                this.clear();
                                this.forUpdate = false;
                                this.selectedOffDays = []
                            }).catch((err) => {
                                if (err.response && err.response.status === 400) {
                                    // Handle the 400 Bad Request error
                                    this.showModal("", err.response.data, "errorArray");
                                } else {
                                    // Handle other types of errors (e.g., network issues, server errors)
                                    console.error('Unexpected error:', err);
                                    this.showModal('An unexpected error occurred', [], "error");
                                }
                            })
                        } else {
                            hrmService.updateWorkingDays(this.wordays_setting, this.wordays_setting.id).then((response) => {
                                // alert('updated working days')
                                this.retreive()
                                this.forUpdate = false
                                this.showModal("Succesfully updated", [], "success");
                                this.clear();
                                this.selectedOffDays = []
                            }).catch((err) => {
                                if (err.response && err.response.status === 400) {
                                    // Handle the 400 Bad Request error
                                    this.showModal("", err.response.data, "errorArray");
                                } else {
                                    // Handle other types of errors (e.g., network issues, server errors)
                                    console.error('Unexpected error:', err);
                                    this.showModal('An unexpected error occurred', [], "error");
                                }
                            })
                        }
                    }
                }
            }
        }
    }
    mounted() {
        this.retreive()
    }
    forUpdate = false
    onRowClicked(data: any) {
        this.forUpdate = true;
        this.wordays_setting = data

        if (this.wordays_setting.shift_type === 'single') {
            this.single.type = this.wordays_setting.mon_type
            this.single.start = this.wordays_setting.mon_start
            this.single.end = this.wordays_setting.mon_end
            this.selectedOffDays = [];
            const daysMapping: any = {
                'mon': 'Monday',
                'tue': 'Tuesday',
                'wed': 'Wednesday',
                'thu': 'Thursday',
                'fri': 'Friday',
                'sat': 'Saturday',
                'sun': 'Sunday',
            };
            const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
            days.forEach(day => {
                if (this.wordays_setting[`${day}_type`] === 'Off') {
                    const fullDayName: any = daysMapping[day];
                    this.selectedOffDays.push(fullDayName);
                }
            });
        }
        else if (this.wordays_setting.shift_type === 'indvidual') {
            this.wordays_setting.shift_type = this.wordays_setting.shift_type
        }
        else {
            this.wordays_setting.shift_type = 'single'
            this.single.type = this.wordays_setting.mon_type
            this.single.start = this.wordays_setting.mon_start
            this.single.end = this.wordays_setting.mon_end
            this.selectedOffDays = [];
        }


        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-workdays");
    }
    items: any = []
    get rows() {
        return this.items.length;
    }
    retreive() {
        this.loading = true;
        hrmService.getWorkingDays().then((resp) => {
            this.items = resp.data;
            this.loading = false;
        }).catch((e) => {
            this.loading = false;
            console.log(e);
        });

    }

    validateTimeForSingle(selectedOption: string[], start: any, end: any) {
        const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
        const commonSettings = {
            type: this.single['type'],
            start: start,
            end: end,
        };
        days.forEach(day => {
            this.wordays_setting[`${day}_type`] = commonSettings.type;
            this.wordays_setting[`${day}_start`] = commonSettings.start;
            this.wordays_setting[`${day}_end`] = commonSettings.end;
        })
        selectedOption.forEach(element => {
            const dayAbbreviation = element.slice(0, 3).toLowerCase();
            const day = days.find(d => d === dayAbbreviation);
            if (day) {
                this.wordays_setting[`${day}_type`] = "Off";
                this.wordays_setting[`${day}_start`] = "00:00:00";
                this.wordays_setting[`${day}_end`] = "23:59:59";
            }

        })

    }
    validateTime() {

        const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

        for (const day of daysOfWeek) {
            if (this.wordays_setting[`${day}_type`] === 'Off') {

                this.wordays_setting[`${day}_start`] = '00:00:00';
                this.wordays_setting[`${day}_end`] = '23:59:59';
            }
        }
        return true
    }
    clear() {
        this.forUpdate = false
        this.wordays_setting = {}
        this.single = {
            type: "",
            start: "",
            end: "",
        }

    }
    handleButtonClick(item: any) {
        this.selectedItem = item;
        this.sidebarVisible = true;
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-details");
    }
}
